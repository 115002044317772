<template>
  <section class="px-4 relative top-20 pb-32 md:pb-40 md:pt-20 pt-12">
    <div class="container">
      <div class="max-w-[960px] w-full mx-auto">
        <h1 class="font-bold text-agra-green-dark font-montserrat mb-10 lg:mb-12 text-3xl md:text-5xl w-full">About
        </h1>
        <h2 class="text-4xl text-agra-green-dark font-semibold border-b-2 border-agra-green pb-12 mb-12">
          {{ staticContent['About Main Heading'] }}
        </h2>
        <p v-html="staticContent['About full text 1']" class="text-agra-green-dark mb-6">
          
        </p>
        
        <h4 class="font-semibold text-agra-green-dark text-2xl py-4">
          {{ staticContent['About subheading 1'] }}
        </h4>
        
        <p v-html="staticContent['About full text 2']" class="mb-6 text-agra-green-dark"></p>

        <img src="/images/hero.jpg" alt="hero" class="w-full block my-6">
        <div class="border-b-2 border-t-2 border-agra-orange py-6 my-6" v-if="staticContent['About Quote']">
          <blockquote class="text-2xl font-semibold italic pb-6">“{{ staticContent['About Quote'] }}”
          </blockquote>
        </div>
        
         <h4 class="font-semibold text-agra-green-dark text-2xl py-4">
          {{ staticContent['About subheading 2'] }}
        </h4>

        <p v-html="staticContent['About full text 3']" class="mb-6 text-agra-green-dark"></p>

        <a href="#" class="inline-flex items-center text-agra-green-dark border border-agra-green-dark px-3 mb-3 py-2 rounded text-xs">
          <span>Share</span> <i class="fas fa-arrow-right transform -rotate-45 ml-2"></i> </a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState([
      'staticContent'
    ])
  }
}
</script>
